import './Bracket.css';
import React from 'react';

function Bracket({ matchData }) {
    const bracketName = matchData?.Data?.Bracket ?? '';

    let padding = '0px';
    if (bracketName.length <= 5) {
        padding = '30px';
    } else if (bracketName.length === 6) {
        padding = '35px';
    }

    return (
        <div className='Bracket'>
            <div className='BracketPadding' style={{ width: padding }}></div>
            {bracketName}
        </div>
    );
}

export default Bracket;
