import './ScoreboardWft.css';
import { ReactComponent as TimeoutAvailable } from '../Timeout/TimeoutAvailable.svg';
import { ReactComponent as TimeoutUsed } from '../Timeout/TimeoutUsed.svg';

function ScoreboardWft({ matchData }) {
    if ((matchData?.Data?.Yellow?.Players || []).length > 0) {
        return (
            <div className='ScoreboardWft'>
                {matchData.Data && <Event matchData={matchData} />}
                {matchData.Data && <LeftTeam matchData={matchData} />}
                {matchData.Data && <Logo />}
                {matchData.Data && <RightTeam matchData={matchData} />}
                {matchData.Data && <Bracket matchData={matchData} />}
            </div>
        );
    }

    return (
        <div className='ScoreboardWft'></div>
    );
}

function Event({ matchData }) {
    return (
        <div className='EventWft'>
            {matchData?.Data?.Event?.Name ?? ''}
        </div>
    );
}

function LeftTeam({ matchData }) {
    const team = matchData?.Data?.Yellow;

    return (
        <div className='TeamWft'>
            <div className='TeamScoreWft'>{matchData?.VolatileData?.ShowScore && (matchData?.Data?.Yellow?.Points ?? 0)}</div>
            <div className='TeamContentWft'>
                <div className='TeamDetailsWft'>
                    <div className='TeamNamesWft Left'>
                        <div className='PlayerNameWft Left'>
                            {(team?.Players ?? []).length > 0 && <div>{formatPlayerName(team.Players[0].FullName)}</div>}
                        </div>
                        <div className='PlayerNameWft Left'>
                            {(team?.Players ?? []).length > 1 && <div>{formatPlayerName(team.Players[1].FullName)}</div>}
                        </div>
                    </div>
                    <TeamTimeoutsWft matchData={matchData} team={team}></TeamTimeoutsWft>
                </div>
                <TeamGamesWft matchData={matchData} team={team} />
            </div>
        </div>
    );
}

function Logo() {
    return (
        <div className='LogoWft'></div>
    );
}

function RightTeam({ matchData }) {
    const team = matchData?.Data?.Black;

    return (
        <div className='TeamWft'>
            <div className='TeamContentWft'>
                <div className='TeamDetailsWft'>
                    <TeamTimeoutsWft matchData={matchData} team={team}></TeamTimeoutsWft>
                    <div className='TeamNamesWft Right'>
                        <div className='PlayerNameWft Right'>
                            {(team?.Players ?? []).length > 0 && <div>{formatPlayerName(team.Players[0].FullName)}</div>}
                        </div>
                        <div className='PlayerNameWft Right'>
                            {(team?.Players ?? []).length > 1 && <div>{formatPlayerName(team.Players[1].FullName)}</div>}
                        </div>
                    </div>
                </div>
                <TeamGamesWft matchData={matchData} team={team} />
            </div>
            <div className='TeamScoreWft'>{matchData?.VolatileData?.ShowScore && (matchData?.Data?.Black?.Points ?? 0)}</div>
        </div>
    );
}

function Bracket({ matchData }) {
    return (
        <div className='BracketWft'>
            {matchData?.Data?.Bracket ?? ''}
        </div>
    );
}

function TeamGamesWft({ matchData, team }) {
    if (matchData?.VolatileData?.ShowScore && matchData?.Data?.Format?.Name === 'Best of' && (matchData?.Data?.Format?.Value ?? 0) > 0) {
        const totalGames = matchData.Data.Format.Value;
        const gamesWon = team.Games;
        const unplayedGames = Math.max(0, totalGames - gamesWon);
        const widthPercentage = `${100 / totalGames}%`;

        return (
            <div className='TeamGamesWft'>
                {[...Array(gamesWon)].map((e, i) => <div className='GameWft Won' style={{ width: widthPercentage }} key={i}></div>)}
                {[...Array(unplayedGames)].map((e, i) => <div className='GameWft Unplayed' style={{ width: widthPercentage }} key={gamesWon + i}></div>)}
            </div>
        );
    }

    return (
        <div></div>
    );
}

function TeamTimeoutsWft({ matchData, team }) {
    if (!matchData?.VolatileData?.ShowScore) {
        return <div className='TeamTimeoutsWft'></div>
    }

    return (
        <div className='TeamTimeoutsWft'>
            {(team?.TimeoutsUsed ?? 0) > 0 ? <TimeoutUsed /> : <TimeoutAvailable />}
            {(team?.TimeoutsUsed ?? 0) > 1 ? <TimeoutUsed /> : <TimeoutAvailable />}
        </div>
    );
}

function formatPlayerName(name) {
    var formattedName = name ?? '';
    formattedName = formattedName.trim();
    return formattedName;
}

export default ScoreboardWft;
