import './Score.css';
import '../../styles/TeamColors.css'
import React from 'react';
import { ReactComponent as TimeoutAvailable } from '../Timeout/TimeoutAvailable.svg';
import { ReactComponent as TimeoutUsed } from '../Timeout/TimeoutUsed.svg';
import Timer from '../Timer/Timer';

function Score({ matchData }) {
    const queryParameters = new URLSearchParams(window.location.search);
    const showScore = matchData?.VolatileData?.ShowScore ?? false;
    const showStartTime = queryParameters.get('showStartTime')?.toLowerCase() === 'true';
    const swapColors = queryParameters.get('swapColors')?.toLowerCase() === 'true';

    const leftTeam = swapColors ? matchData?.Data?.Black : matchData?.Data?.Yellow;
    const rightTeam = swapColors ? matchData?.Data?.Yellow : matchData?.Data?.Black;

    if (showStartTime && matchData?.Data?.ScheduledTime) {
        const startTime = new Date(matchData?.Data?.ScheduledTime);
        const now = new Date();

        if (startTime > now) {
            const remainingTime = msToTime(startTime - now);
            return <div className='MatchPending'>Starts in {remainingTime}</div>
        }
    }

    if (showScore && matchData?.Data?.Event?.Name && matchData?.Data?.Event?.Name !== ' ') {
        return (
            <div className='Score'>
                <div className='Timeouts'>
                    {(leftTeam?.TimeoutsUsed ?? 0) > 0 ? <TimeoutUsed /> : <TimeoutAvailable />}
                    {(leftTeam?.TimeoutsUsed ?? 0) > 1 ? <TimeoutUsed /> : <TimeoutAvailable />}
                </div>
                <Points matchData={matchData} leftTeam={leftTeam} rightTeam={rightTeam} swapColors={swapColors} />
                <div className='Timeouts'>
                    {(rightTeam?.TimeoutsUsed ?? 0) > 0 ? <TimeoutUsed /> : <TimeoutAvailable />}
                    {(rightTeam?.TimeoutsUsed ?? 0) > 1 ? <TimeoutUsed /> : <TimeoutAvailable />}
                </div>
            </div>
        );
    } else if (matchData?.Data?.Event?.Name === ' ') {
        return (
            <div className='Waiting'>Waiting for the next match</div>
        );
    } else {
        return (
            <div className='NoScore'>VS</div>
        );
    }
}

function Points({ matchData, leftTeam, rightTeam, swapColors }) {
    const duration = matchData?.VolatileData?.Timer?.DurationMilliseconds;
    const startTime = matchData?.VolatileData?.Timer?.StartTimeEpoch;

    if (duration > 0 && startTime > 0) {
        const now = Date.now();
        const expiration = startTime + duration;
        const millisecondsRemaining = expiration - now;

        if (millisecondsRemaining <= 3500 && millisecondsRemaining >= -1500) {
            return (<Timer expiration={expiration} />);
        }
    }

    return (
        <div className='Points'>
            <GameScore matchData={matchData} leftTeam={leftTeam} rightTeam={rightTeam} />
            <MatchScore matchData={matchData} swapColors={swapColors} />
        </div>
    );
}

function GameScore({ matchData, leftTeam, rightTeam }) {
    return (
        <div className='GameScore'>
            <div className='LeftTeamScore'>{matchData?.Data ? `${leftTeam?.Points ?? ''}` : ''}</div>
            <div className='ScoreSeparator'>:</div>
            <div className='RightTeamScore'>{matchData?.Data ? `${rightTeam?.Points ?? ''}` : ''}</div>
        </div>
    );
}

function MatchScore({ matchData, swapColors }) {
    if (matchData?.Data?.Format?.Name === 'Best of' && (matchData?.Data?.Format?.Value ?? 0) > 0) {
        const totalGames = matchData.Data.Format.Value;
        const leftTeamGames = swapColors ? matchData.Data.Black.Games : matchData.Data.Yellow.Games;
        const rightTeamGames = swapColors ? matchData.Data.Yellow.Games : matchData.Data.Black.Games;
        const unplayedGames = Math.max(0, totalGames - leftTeamGames - rightTeamGames);
        const gamesToWinMatch = Math.floor(totalGames / 2) + 1;
        const widthPercentage = `${100 / totalGames}%`;

        return (
            <div className='MatchScore'>
                <div className='GamesToWinMatchTitle'>{`${gamesToWinMatch} game${gamesToWinMatch === 1 ? '' : 's'} to win match`}</div>
                <div className='GameCounter'>
                    {[...Array(leftTeamGames)].map((e, i) => <div className={'Game ' + (swapColors ? 'BlackTeamColors' : 'YellowTeamColors')} style={{ width: widthPercentage }} key={i}></div>)}
                    {[...Array(unplayedGames)].map((e, i) => <div className='Game GameUnplayed' style={{ width: widthPercentage }} key={leftTeamGames + i}></div>)}
                    {[...Array(rightTeamGames)].map((e, i) => <div className={'Game ' + (swapColors ? 'YellowTeamColors' : 'BlackTeamColors')} style={{ width: widthPercentage }} key={leftTeamGames + unplayedGames + i}></div>)}
                </div>
            </div>
        );
    }

    return (
        <div></div>
    );
}

function msToTime(duration) {
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    return `${hours} hr ${minutes} min`;
}

export default Score;
